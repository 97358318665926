import EmojiComponent from '@pretto/bricks/components/iconography/Emoji'

import PropTypes from 'prop-types'
import { Children } from 'react'

const getStringFromChildren = (children = []) =>
  Children.toArray(children).reduce((previous, child) => {
    if (child.props) {
      return `${previous}\n\r${getStringFromChildren(child.props.children)}`
    }

    return previous + child
  }, '')

const Emoji = ({ children, ...props }) => <EmojiComponent {...props}>{getStringFromChildren(children)}</EmojiComponent>

Emoji.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Emoji
